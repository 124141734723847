<template>
  <div class="document-item">
    <v-row align="center">
      <v-col cols="4">
        <div class="d-flex align-center">
          <div class="document-item__type-icon">
            <IconQuotaValidation />
          </div>
          <div class="document-item__title" @click="$emit('downloadPdf')">
            {{ doc.filename }}
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col col="6" class="text-right py-0"></v-col>
          <v-col col="6" class="py-0">{{ doc.file_type }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-row justify="space-between" align="center">
          <v-col cols="auto">
            <v-btn
              :loading="loading"
              class="document-item__action-icon"
              color="primary"
              text
              icon
              @click="$emit('downloadPdf')"
            >
              <v-icon color="primary">mdi-tray-arrow-down</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="py-0">
            <div class="d-flex align-center">
              <span class="status">{{
                $store.getters["quota/getQuotaStatusText"]
              }}</span>
              <IconQuotaEdit v-if="status === 1 || status === 2" />
              <IconQuotaNotValid
                v-else-if="status === 3 || status === 4"
                class="ml-2"
              />
              <v-icon
                v-else-if="status === 5"
                color="success"
                class="ml-1"
                small
                >mdi-check-circle</v-icon
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "QuotaPdfItem",
  props: {
    doc: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    IconQuotaValidation: () => import("~cp/Icons/IconQuotaValidation"),
    IconQuotaEdit: () => import("~cp/Icons/IconQuotaEdit"),
    IconQuotaNotValid: () => import("~cp/Icons/IconQuotaNotValid"),
  },
  filters: {
    mb(value) {
      if (!value) return "0";
      return (value / 1048576).toFixed(2);
    },
  },
  computed: {
    status() {
      return this.$store.getters["quota/getQuotaStatus"];
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: inline-block;
  max-width: 135px;
  line-height: 1.2;
  padding-right: 3px;
  font-size: 13px;
}
</style>>
